import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Input from "../../../ui/components/Input/Input";
import {useTranslation} from "react-i18next";
import cl from './UserForm.module.css';
import UserFormRequestsGrid from "./components/UserFormRequestsGrid";
import UserFormAccountsGrid from "./components/UserFormAccountsGrid";
import {DateTimePickerComponent, Inject, MaskedDateTime} from "@syncfusion/ej2-react-calendars";
import CamerasGrid from "./components/CamerasGrid";

const UserForm = ({user, ...rest}) => {
    const {t} = useTranslation();

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className='input-container'>
                        <label className='label'>
                            {t("user.form.name")}
                        </label>
                        <Input name='name'
                               type='text'
                               placeholder={t("user.form.name")}
                               disabled={true}
                               autoComplete='off'
                               value={user.name}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("user.form.surname")}
                        </label>
                        <Input name='surname'
                               type='text'
                               placeholder={t("user.form.surname")}
                               disabled={true}
                               autoComplete='off'
                               value={user.surname}/>
                    </div>

                    <Row>
                        <Col>
                            <div className='input-container'>
                                <label className='label'>
                                    {t("user.form.middle-name")}
                                </label>
                                <Input name='middleName'
                                       type='text'
                                       placeholder={t("user.form.middle-name")}
                                       disabled={true}
                                       autoComplete='off'
                                       value={user.middleName}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='input-container syncfusion-control-input-container'>
                                <label className='label'>
                                    {t("user.form.birthday")}
                                </label>
                                <DateTimePickerComponent placeholder={t("user.form.birthday")}
                                                         enabled={false}
                                                         value={user.birthdate}
                                                         enableMask={true}
                                                         format='dd.MM.yyyy'
                                                         maskPlaceholder={{
                                                             day: 'dd',
                                                             month: 'MM',
                                                             year: 'yyyy'
                                                         }}>
                                    <Inject services={[MaskedDateTime]}/>
                                </DateTimePickerComponent>
                            </div>
                        </Col>
                    </Row>

                    <div className='input-container checkbox'>
                        <Input name='isBlocked'
                               type='checkbox'
                               disabled={true}
                               checked={user.isBlocked}/>
                        <label className='label'>
                            {t("user.form.blocked")}
                        </label>
                    </div>
                    {user && user.userSetting &&
                        <>
                            <div className='input-container checkbox'>
                                <Input name='showAllIncidents'
                                       type='checkbox'
                                       disabled={true}
                                       checked={!user.userSetting.showAllIncidents}/>
                                <label className='label'>
                                    {t("user.form.show-my-incidents")}
                                </label>
                            </div>

                            <div className='input-container checkbox'>
                                <Input name='stopNewsNotification'
                                       type='checkbox'
                                       disabled={true}
                                       checked={!user.userSetting.stopNewsNotification}/>
                                <label className='label'>
                                    {t("user.form.receive-news-notifications")}
                                </label>
                            </div>
                        </>
                    }
                    <div className='input-container checkbox'>
                        <Input name='isAllowVote'
                               type='checkbox'
                               disabled={true}
                               checked={user.isAllowVote}/>
                        <label className='label'>
                            {t("user.form.is-allow-vote")}
                        </label>
                    </div>

                    <div className='input-container checkbox'>
                        <Input name='isAllowCheckLists'
                               type='checkbox'
                               disabled={true}
                               checked={user.isAllowCheckLists}/>
                        <label className='label'>
                            {t("user.form.is-allow-check-lists")}
                        </label>
                    </div>
                </Col>
                <Col>
                    <div className='input-container'>
                        <label className='label'>
                            {t("user.form.phone")}
                        </label>
                        <Input name='phone'
                               type='text'
                               placeholder={t("user.form.phone")}
                               disabled={true}
                               autoComplete='off'
                               value={user.phone}/>
                    </div>
                    <div className='input-container'>
                        <label className='label'>
                            {t("user.form.email")}
                        </label>
                        <Input name='email'
                               type='text'
                               placeholder={t("user.form.email")}
                               disabled={true}
                               autoComplete='off'
                               value={user.email}/>
                    </div>

                    <div className='input-container'>
                        <label className='label'>
                            {t("user.form.crm-id")}
                        </label>
                        <Input name='crmId'
                               type='text'
                               placeholder={t("user.form.crm-id")}
                               disabled={true}
                               autoComplete='off'
                               value={user.crmId}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{margin: '10px 0'}}>
                    <div className={cl.header}>
                        {t("user.form.accounts-label")}
                    </div>
                    <UserFormAccountsGrid accounts={user.accounts} {...rest}/>
                </Col>
            </Row>
            <Row>
                <Col style={{margin: '10px 0'}}>
                    <div className={cl.header}>
                        {t("user.form.account-request-label")}
                    </div>
                    <UserFormRequestsGrid requests={user.accountRequests}/>
                </Col>
            </Row>
            <Row>
                <Col style={{margin: '10px 0'}}>
                    <div className={cl.header}>
                        {t("user.form.cameras")}
                    </div>
                    <CamerasGrid requests={user.cameras}/>
                </Col>
            </Row>
        </Container>
    );
};

export default UserForm;

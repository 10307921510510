import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import moment from "moment/moment";
import * as Yup from "yup";
import {Container, Row, Col} from "react-bootstrap";
import Target from "../../../ui/components/Target/Target";
import cl from './BuildingForm.module.css';
import {Query} from "@syncfusion/ej2-data";
import DropDownList from "../../../ui/components/DropDownList/DropDownList";
import Input from "../../../ui/components/Input/Input";



const BuildingForm = ({
                      id,
                      street, number, section, subSection, homeOwner, unetOrgId, isCommercial, isGroup,
                      onSubmit
                  }) => {

    const {t} = useTranslation();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id, isCommercial:isCommercial,isGroup:isGroup,
            street : street,
            number : number, section: section, subSection : subSection, homeOwner : homeOwner, unetOrgId : unetOrgId
        },

        onSubmit: onSubmit
    });

    return (
        <Container fluid>
            <form id='page-form' onSubmit={form.handleSubmit}>

                <Row className={cl.formRow}>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label required'>
                                {t("building.form.street")}
                            </label>
                            <Input name='street'
                                   type='text'
                                   placeholder={t("building.form.street")}
                                   disabled={true}
                                   autoComplete='off'
                                   value={form.values.street}/>
                        </div>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("building.form.number")}
                            </label>
                            <Input name='number'
                                   type='text'
                                   placeholder={t("building.form.number")}
                                   disabled={true}
                                   autoComplete='off'
                                   value={form.values.number}/>
                        </div>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("building.form.section")}
                            </label>
                            <Input name='section'
                                   type='text'
                                   placeholder={t("building.form.section")}
                                   disabled={true}
                                   autoComplete='off'
                                   value={form.values.section}/>
                        </div>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("building.form.subsection")}
                            </label>
                            <Input name='subSection'
                                   type='text'
                                   placeholder={t("building.form.subsection")}
                                   disabled={true}
                                   autoComplete='off'
                                   value={form.values.subSection}/>
                        </div>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("building.form.homeowner")}
                            </label>
                            <Input name='homeOwner'
                                   type='text'
                                   placeholder={t("building.form.homeowner")}
                                   disabled={true}
                                   autoComplete='off'
                                   value={form.values.homeOwner}/>
                        </div>
                        <div className='input-container checkbox'>
                            <Input name='isCommercial'
                                   type='checkbox'
                                   disabled={true}
                                   checked={form.values.isCommercial}/>
                            <label className='label'>
                                {t("building.form.iscommercial")}
                            </label>
                        </div>
                        <div className='input-container checkbox'>
                            <Input name='isGroup'
                                   type='checkbox'
                                   disabled={true}
                                   checked={form.values.isGroup}/>
                            <label className='label'>
                                {t("building.form.isgroup")}
                            </label>
                        </div>
                    </Col>
                    <Col>
                        <div className='input-container syncfusion-control-input-container'>
                            <label className='label'>
                                {t("building.form.unetorgid")}
                            </label>
                            <Input name='unetOrgId'
                                   type='text'
                                   placeholder={t("building.form.unetorgid")}
                                   disabled={form.isSubmitting}
                                   onChange={form.handleChange}
                                   autoComplete='off'
                                   value={form.values.unetOrgId}/>
                        </div>
                    </Col>
                </Row>


            </form>

        </Container>
    );
};

export default BuildingForm;

import {createSlice} from "@reduxjs/toolkit";
import HomeOwnerPoll from "../../components/pages/grid/HomeOwnerPoll/HomeOwnerPoll";

const initialState = {
    setting: [],
    admin: [],
    user: [],
    userLog: [],
    account: [],
    post: [],
    page: [],
    source: [],
    category: [],
    banner: [],
    problemUser: [],
    poll: [],
    link:[],
    building:[],
    videocamera:[],
    homeOwnerPoll:[],
    homeOwnerErip:[]
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        appendFilter: (state, action) => {
            if (state[action.payload.type].find(f => f.field === action.payload.value.field)) {
                state[action.payload.type].find(f => f.field === action.payload.value.field).value = action.payload.value.value;
            } else {
                state[action.payload.type].push(action.payload.value);
            }
        },
        removeFilter: (state, action) => {
            if (state[action.payload.type].find(f => f.field === action.payload.field)) {
                state[action.payload.type] = state[action.payload.type].filter(f => f.field !== action.payload.field);
            }
        }
    }
});

export const {appendFilter, removeFilter} = filterSlice.actions;

export default filterSlice.reducer;

import React from 'react';
import {useTranslation} from "react-i18next";
import DropdownGridFilter from "../../../../ui/components/Grid/filters/DropdownGridFilter";

const BuildingStateFilter = ({onFilterChange, ...rest}) => {
    const {t} = useTranslation();

    const stateFilterData = [
        {id: 'checked', text: t("building.grid.checked")},
        {id: 'not-checked', text: t("building.grid.not-checked")},
    ];

    return <DropdownGridFilter onChange={onFilterChange} {...rest} data={stateFilterData}/>
};

export default BuildingStateFilter;

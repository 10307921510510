import React from 'react';
import {useTranslation} from "react-i18next";
import {ColumnDirective, ColumnsDirective, GridComponent} from "@syncfusion/ej2-react-grids";

const CamerasGrid = ({requests}) => {
    const {t} = useTranslation();

    return (
        <GridComponent dataSource={requests}
                       id='user-cameras-grid'
                       height='300px'
                       locale='ru-RU'
                       width='100%'>
            <ColumnsDirective>
                <ColumnDirective field='camera'
                                 headerText={t("user.form.camera.name")}
                                 width='40%'
                                 textAlign="center"/>
                <ColumnDirective field='cameraAddress'
                                 headerText={t("user.form.camera.address")}
                                 width='60%'
                                 textAlign="center"/>
            </ColumnsDirective>
        </GridComponent>
    );
};

export default CamerasGrid;

import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSelectors";
import {useNavigate} from "react-router-dom";
import PermissionType from "../../../../data/enums/PermissionType";
import GridTabSection, {GridTabSectionType} from "../../../ui/components/Grid/GridTab/GridTabSection/GridTabSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GridTabItem from "../../../ui/components/Grid/GridTab/GridTabItem/GridTabItem";
import {faAdd, faRefresh} from "@fortawesome/free-solid-svg-icons";
import GridTab from "../../../ui/components/Grid/GridTab/GridTab";
import Loader from './../../../ui/components/Loader/Loader';
import {Query} from "@syncfusion/ej2-data";
import {ColumnDirective, ColumnsDirective, Filter, Inject, Page as Pager, Sort} from "@syncfusion/ej2-react-grids";
import GridComponent from "../../../ui/components/Grid/GridComponent";
import UserStateFilter from "../User/filters/UserStateFilter";
import BuildingStateFilter from "./filters/BuildingStateFilter";

const Building = () => {
    const {t} = useTranslation();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const [tab, setTab] = useState('0');

    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [filterTrigger, setFilterTrigger] = useState(null);
    const [query, setQuery] = useState(null);


    useEffect(() => {
        setQuery(prev => {
            let q = new Query();
            if (prev) {
                if (prev && prev.params && prev.params.length > 0) {
                    const params = prev.params.filter(item => item.key !== 'tab');
                    for (const item of params) {
                        q.addParams(item.key, item.value);
                    }
                }
                q.addParams('tab', tab);
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('tab')) {
                    q.addParams('tab', urlParams.get('tab'));
                } else {
                    q.addParams('tab', tab);
                }
            }

            return q;
        });
    }, [tab]);

    const onTabItemClick = ({id, queryParameterName, queryKey}) => {
        if (queryParameterName && queryKey) {
            setTab(queryKey);
        } else if (id) {
            if (id === 'refresh-btn') {
                setRefreshTrigger(prev => prev + 1);
            } else if (id === 'add-btn') {
                navigate('/management/buildings/building/');
            }

        }
    }

    const onRowDoubleClick = ev => {
        if (ev && ev.rowData) {
            navigate(`/management/buildings/building/${ev.rowData.id}`);
        }
    }
    const onFilterChange = (ev, fieldName) => setFilterTrigger({
        ev,
        fieldName
    });
    return (
        <div className='list-container'>
                  <GridTab onClick={onTabItemClick}>
                      <GridTabSection type={GridTabSectionType.Tab}
                                      showRightSeparator={true}
                                      queryParameterName='tab'>
                          <GridTabItem id='all-tab'
                                       text={t("building.grid.not-commercial-tab")}
                                       queryKey='0'
                                       isDefaultActive={true}>

                          </GridTabItem>
                          <GridTabItem id='blocked-tab'
                                       text={t("building.grid.commercial-tab")}
                                       queryKey='1'>

                          </GridTabItem>

                      </GridTabSection>
                <GridTabSection type={GridTabSectionType.Button}>

                    <GridTabItem id='refresh-btn'
                                 text={t("btn.refresh")}
                                 icon={<FontAwesomeIcon icon={faRefresh}/>}>

                    </GridTabItem>
                </GridTabSection>
            </GridTab>
            {!query
                ? <Loader/>
                : <GridComponent id='grid'
                                 container='.list-container'
                                 url='/api/Building/getBuildingList'
                                 query={query}
                                 filterStoreEntityName={'building'}
                                 onRowClick={onRowDoubleClick}
                                 refresh={refreshTrigger}
                                 filter={filterTrigger}>
                    <ColumnsDirective>
                        <ColumnDirective field='id'
                                         visible={false}
                                         textAlign="center"
                                         isPrimaryKey={true}
                                         type='number'/>


                        <ColumnDirective field='street'
                                         headerText={t("building.grid.street")}
                                         width='350'
                                         textAlign='center'/>

                        <ColumnDirective field='number'
                                         headerText={t("building.grid.number")}
                                         width='200'
                                         textAlign='center'/>
                        <ColumnDirective field='section'
                                         headerText={t("building.grid.section")}
                                         width='200'
                                         textAlign='center'/>
                        <ColumnDirective field='subSection'
                                         headerText={t("building.grid.subsection")}
                                         width='200'
                                         textAlign='center'/>
                        <ColumnDirective field='homeOwner'
                                         headerText={t("building.grid.homeowner")}
                                         width='200'
                                         textAlign='center'/>
                        <ColumnDirective field='unetOrgId'
                                         headerText={t("building.grid.unetorgid")}
                                         width='200'
                                         textAlign='center'/>

                        <ColumnDirective field='isGroup'
                                         headerText={t("building.grid.isgroup")}
                                         width='170'
                                         filterTemplate={(props) => <BuildingStateFilter
                                             onFilterChange={onFilterChange}
                                             filterStoreEntityName={'building'} {...props}/>}
                                         displayAsCheckBox={true}
                                         textAlign='center'/>
                    </ColumnsDirective>
                    <Inject services={[Pager, Sort, Filter]}/>
                </GridComponent>
            }
        </div>
    );
}
export default Building;
